








import { Component, Vue } from 'vue-property-decorator';
import HelloWorld from './components/HelloWorld.vue';
import MainPage from './components/MainPage.vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


@Component({
  components: {
    HelloWorld,
    MainPage,
  },
})
export default class App extends Vue {}
