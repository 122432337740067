
export class Line {
    public vx: number;
    public vy = 10;
    public xPos = 0;
    public yPos = 0;
    public isXDirec = false;
    public xSpeed = 0.5;
    public ySpeed = 0.5;
    public lineLength = 0;
    public lines: any[] = [];
    public directRestrict = '';
    public minLength = 100;
    // (xPoint+5*i,yPoint+textHeight,"-y",false);

    constructor(xPos: number, yPos: number, directRestrict: string, isXDirec: boolean) {
        this.vx = 12;
        this.vy = 10;
        this.xPos = xPos;
        this.yPos = yPos;
        this.isXDirec = isXDirec;
        this.xSpeed = 0.5;
        this.ySpeed = 0.5;
        this.lineLength = 0;
        this.lines = [[xPos, yPos]];
        this.directRestrict = directRestrict;
        this.minLength = 100;
        [this.xSpeed, this.ySpeed] = this.convertWithRestrict(this.directRestrict, this.xSpeed, this.ySpeed);
    }



    public draw(canvas: HTMLCanvasElement, ctx: any) {
        if (this.xPos > canvas.width || this.xPos < 0 || this.yPos < 0 || this.yPos > canvas.height) {
            return;
        }

        ctx.save();
        ctx.beginPath();
        ctx.moveTo(this.lines[0].xPos, this.lines[0].yPos);
        this.lines.forEach((element) => {
            ctx.lineTo(element[0], element[1]);
        });
        if (Math.random() > 0.99 && this.lineLength > this.minLength) {
            this.isXDirec = !this.isXDirec;
            this.lines.push([this.xPos, this.yPos]);
            this.lineLength = 0;
            if (Math.random() > 0.5) {
                this.ySpeed = -1 * this.ySpeed;
                this.xSpeed = -1 * this.xSpeed;
                [this.xSpeed, this.ySpeed] = this.convertWithRestrict(this.directRestrict, this.xSpeed, this.ySpeed);
                this.minLength = (100 + (Math.random() * 50));
            }

        }

        if (this.isXDirec) {
            this.xPos += this.xSpeed;
            this.lineLength += Math.abs(this.xSpeed);
        } else {
            this.yPos += this.ySpeed;
            this.lineLength += Math.abs(this.ySpeed);
        }

        ctx.lineTo(this.xPos, this.yPos);

        const grad = ctx.createLinearGradient(this.lines[0][0], this.lines[0][1], this.xPos + 1, this.yPos + 1);
        grad.addColorStop(0, '#189ad3');
        grad.addColorStop(0.5, '#b2b2b2');
        grad.addColorStop(1, '#dddddd');

        ctx.strokeStyle = grad;


        ctx.lineWidth = 0.01;
        ctx.stroke();
        ctx.restore();
    }
    private convertWithRestrict(direction: string, xSpeed: number, ySpeed: number) {
        if (direction === '+x') {
            xSpeed = Math.abs(xSpeed) * -1;
        } else if (direction === '-x') {
            xSpeed = Math.abs(xSpeed);
        } else if (direction === '+y') {
            ySpeed = Math.abs(ySpeed) * -1;
        } else if (direction === '-y') {
            ySpeed = Math.abs(ySpeed);
        }
        return [xSpeed, ySpeed];
    }
}
