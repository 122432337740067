




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import PortfolioItem from './PortfolioItem';
import PortfolioData from './PortfolioData';

library.add(faSearchPlus);


@Component
export default class Portfolio extends Vue {

  public static readonly portfolioItemsG: PortfolioItem[] = PortfolioData.PortfolioData;
  public portfolioItems = Portfolio.portfolioItemsG;
  @Prop() private msg!: string;
  public getImageUrl(uri: string) {
        return require('../assets/portfolio' + uri);
  }

  public data() {
    return {
      portfolioItem: Portfolio.portfolioItemsG,
    };
  }

}
