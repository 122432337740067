import Vue from 'vue';
// import vueScrollto from 'vue-scrollto';
import App from './App.vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import '@/scss/freelancer.scss';

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

Vue.config.productionTip = false;

import * as vueScrollto from 'vue-scrollto';

// You can also pass in the default options
Vue.use(vueScrollto, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: -50,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});



const vue = new Vue({
  render: (h) => h(App),
}).$mount('#app');


// (function ($) {
//   "use strict"; // Start of use strict

//   // Scroll to top button appear
//   $(document).scroll(function () {
//     var scrollDistance = $(this).scrollTop();
//     if (scrollDistance > 100) {
//       $('.scroll-to-top').fadeIn();
//     } else {
//       $('.scroll-to-top').fadeOut();
//     }
//   });

//   // Closes responsive menu when a scroll trigger link is clicked
//   $('.js-scroll-trigger').click(function () {
//     $('.navbar-collapse').collapse('hide');
//   });

//   // Activate scrollspy to add active class to navbar items on scroll
//   $('body').scrollspy({
//     target: '#mainNav',
//     offset: 80
//   });

//   // Collapse Navbar
//   var navbarCollapse = function () {
//     if ($("#mainNav").offset().top > 100) {
//       $("#mainNav").addClass("navbar-shrink");
//     } else {
//       $("#mainNav").removeClass("navbar-shrink");
//     }
//   };
//   // Collapse now if page is not at top
//   navbarCollapse();
//   // Collapse the navbar when page is scrolled
//   $(window).scroll(navbarCollapse);

//   // Floating label headings for the contact form
//   $(function () {
//     $("body").on("input propertychange", ".floating-label-form-group", function (e) {
//       $(this).toggleClass("floating-label-form-group-with-value", !!$(e.target).val());
//     }).on("focus", ".floating-label-form-group", function () {
//       $(this).addClass("floating-label-form-group-with-focus");
//     }).on("blur", ".floating-label-form-group", function () {
//       $(this).removeClass("floating-label-form-group-with-focus");
//     });
//   });

// })(jQuery); // End of use strict



