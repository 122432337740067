











































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Circuit from './Circuit.vue';
import Portfolio from './Portfolio.vue';
import ContactUs from './ContactUs.vue';
import Modal from './Modal.vue';
import PortfolioItem from '@/components/PortfolioItem';

import * as vueScrollto from 'vue-scrollto';

@Component({
  components: {
    Circuit,
    Portfolio,
    ContactUs,
    Modal,
  },
  directives: {
      vueScrollto,
  },
})
export default class MainPage extends Vue {
    private portfolioItem: PortfolioItem = Portfolio.portfolioItemsG[0];
    private showModal: boolean = false;

    private portfolioClick(num: number) {
      this.portfolioItem = Portfolio.portfolioItemsG[num];
      this.showModal = true;
    }

}
