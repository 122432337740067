// tslint:disable:max-line-length
import PortfolioItems from './PortfolioItem';

export default class PortfolioData {


    public static readonly PortfolioData: PortfolioItems[] = [
        {
            id: 0,
            title: 'Table AI',
            imgLink: '/robotable.png',
            body: `This Project was created as part of the Imperial College Robotics FoodHackathon, where Table AI won Overall Winners. It revolved around an attempt to cater a more customized dining experience, using micro portions and an AI interface that determined the perfect next portion. This coupled with an innovative food delivery system made for a dining experience one would never forget!`,
            tech: 'This used a flask-based app with keras on the backend and arduino for the embedded firmware',
        }, {
            id: 1,
            title: 'Motor Control',
            imgLink: '/motor.png',
            body: 'This project was part of an amazing embedded systems module coursework. It involved writing a high performance, interrupt driven brushless motor control driver, with position and velocity control.',
            tech: 'This was built upon a small stm32 chip running mbed OS',
        },
        {
            id: 2,
            title: 'EtonConnect',
            imgLink: '/etonconnect.png',
            body: 'Route learning as a technique has propagated throughout the British public education system, and is prevalent across the world. Eton Connect was a project that was designed to change that, by providing an online platform that connected students with video conferencing software, to bring language learning to life. It integrated well with the curriculum, with teachers being able to suggest and mark conversation topics .',
            tech: 'This used an angular frontend with an asp.net backend with WebSockets for server-side notifications',
        },
        {
            id: 3,
            title: 'ARM IEEE 754 Multiplier',
            imgLink: '/arm.png',
            body: 'As part of Computer Architecture coursework, we were tasked with building an IEEE 754 floating point multiplier, using a restricted subset of the arm thumb instruction set.',
        },
        {
            id: 4,
            title: 'Aquise',
            imgLink: '/aquise.png',
            body: 'Ever wanted to change your daily shower mix whilst in the shower, but didn\'t want to risk damaging our phone? Aquise is a waterproof device that is attached onto your shower wall and, using magnetic sensing technology, allows you to control your Bluetooth speaker so that you can control your favorite playlist, every morning. In addition to music control, PIR sensors were used to collect data, and an app tracked and suggested ways to reduce your water usage .',
            tech: 'This use an esp8266 running micro python, python on a RPI and golang on the backend',
        },
        {
            id: 5,
            title: 'ICMesh',
            imgLink: '/icmesh.png',
            body: 'This was a second-year project focussing on ad-hock mesh networks to design efficient data communication and smart car coordination in a driverless future. As well as building the hardware for a proprietary mesh protocol, extensive simulations were performed to test optimization performance.',
            tech: 'C++ game engine used for simulation, mbed used for embedded firmware',
        },
        {
            id: 6,
            title: 'Noiseless',
            imgLink: '/noise.jpg',
            body: 'This was a project to remove noise from audio in real time using spectral subtraction.',
            tech: 'This was done using a TI C6000 series DSP processor',
        },
        {
            id: 7,
            title: 'Lightfox',
            imgLink: '/lightfox.jpg',
            body: 'In the summer of 2017, I spent 2 months at Lightfox, a startup attempting to bring smart lighting systems to large commercial deployments. As a part of this, I was working on their next generation, cloud-connected product, based on the KW41z thread module from NXP.',
            tech: 'python, C for KW41z firmware, golang for PoC web service',
        },
        {
            id: 8,
            title: 'Ocado',
            imgLink: '/OSP.jpg',
            body: 'I am spending my 6  month industrial placement at Ocado Technology, working on the firmware for their robotics platform.',
        },
        {
            id: 9,
            title: 'Imperial GSS',
            imgLink: '/GSS.png',
            body: 'I approached the Imperial Global Summer School, a competitive summer school for aspiring engineers/scientists, with an idea on how to optimize the technology in their admissions process. This progressed to me designing a full web-based application system to track and assess potential applicants. In addition to this, a system was built to assist with the everyday running of the summer school with utilities such as activity signups etc.',
            tech: 'Used a dockerised ASP.net core backend running on Azure and Angular on the frontend.',
        },
        {
            id: 10,
            title: 'Web Crawler',
            imgLink: '/neo4j.png',
            body: 'This project was built to explore graph databases and their uses. A site was crawled and then a series of queries were made with cypher, in order to investiagte the site links',
            tech: 'This project was written in a multithreaded python style, exploting the fact that the primary bottleneck was io. It used SQLite as blob storgae and neo4j to store page links.',

        },
        {
            id: 11,
            title: 'Cabbage',
            imgLink: '/cabbage.png',
            body: 'This project was self proposed as part of a functional programming module. Cabbage is a logic based programming langauge, that supports a declrative syntax to solve first order logic problems',
            tech: 'Written from scratch using F#',
        },
    ];


}
