












import { Component, Prop, Vue } from 'vue-property-decorator';
import {Line } from './Line';






@Component
export default class Circuit extends Vue {


    public allLines: Line[] = [];
    public xPoint: number = 0;
    public readonly textWidth: number = 300;
    public readonly textHeight: number = 100;
    public yPoint: number = 200;
    public canvas: any = null;

    private readonly canvasWidthScaleFactor: number = 0.9;
    private workingWidth: number = 0;
    @Prop() private msg!: string;


    public init() {
        this.canvas = this.$refs.tutorialAA as HTMLCanvasElement;

        this.canvas.width = window.innerWidth * this.canvasWidthScaleFactor;
        const ctx = this.canvas.getContext('2d');
        window.requestAnimationFrame(this.draw);
        if (ctx == null || !ctx) {
            alert('It looks like your browser doesnt support the canvas element');
            return;
        }

        const headerElement: HTMLElement =  this.$refs.headerpl as HTMLCanvasElement;
        headerElement.remove();

        this.canvas.height = 500;
        this.workingWidth = window.innerWidth;

        this.xPoint = (this.canvas.width / 2) - 100;
        this.yPoint = 200;
        this.allLines = [];
        for (let i = 0; i < (this.textHeight / 5) + 1; i++) {
            const line = new Line(this.xPoint + this.textWidth, this.yPoint + 5 * i, '-x', true);
            this.allLines.push(line);
            const lineB = new Line(this.xPoint, this.yPoint + 5 * i, '+x', true);
            this.allLines.push(lineB);

            }
        for (let i = 0; i < (this.textWidth / 5) + 1; i++) {
            const lineC = new Line(this.xPoint + 5 * i, this.yPoint + this.textHeight, '-y', false);
            this.allLines.push(lineC);
            const lineD = new Line(this.xPoint + 5 * i, this.yPoint, '+y', false);
            this.allLines.push(lineD);
        }

    }


    public draw(timestamp: number) {
        const now = Date.now();
        // let canvas = document.getElementById('tutorial');

        const ctx: CanvasRenderingContext2D = this.canvas.getContext('2d') as CanvasRenderingContext2D ;
        if ( this.workingWidth !== window.innerWidth) {
            ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.init();
        }

        if (timestamp % 100 === 0) {
            ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);


        }

        ctx.clearRect(this.xPoint, this.yPoint, this.textWidth, this.textHeight);

        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#eeeeee';
        ctx.font = '30px Lato,sans-serif, Ariel';
        ctx.fillText('Electrical Engineer', this.xPoint + 25, this.yPoint + 50);


        ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
        ctx.strokeStyle = '#189ad3';
        ctx.strokeRect(this.xPoint, this.yPoint, this.textWidth, this.textHeight);


        for (const line of this.allLines) {
            line.draw(this.canvas, ctx);
        }

        window.requestAnimationFrame(this.draw);
    }

    public mounted() {

        this.init();
    }

}
