






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import PortfolioItem from '@/components/PortfolioItem';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

library.add(faTimesCircle);

@Component
export default class Modal extends Vue {
  @Prop({ default: { title: '', body: '', imgLink: '' } })
  private item!: PortfolioItem;

  public getImageUri(name: string) {
    return require('../assets/portfolio' + name);
  }
}
