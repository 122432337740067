import { render, staticRenderFns } from "./MainPage.vue?vue&type=template&id=8ed2a596&"
import script from "./MainPage.vue?vue&type=script&lang=ts&"
export * from "./MainPage.vue?vue&type=script&lang=ts&"
import style0 from "./MainPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "MainPage.vue"
export default component.exports